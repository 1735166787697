@import url(../../css/theme.css);

.bg-header {
    background-color: var(--dark-blue);
}

.navbar {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.nav-function-top .page-header {
    margin-top: 0;
    height: 4.125rem;
    background-color: var(--dark-blue);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    z-index: 2;
}

.search-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-icon:not(.btn) {
    min-width: 3.125rem;
    text-align: center;
    overflow: visible;
}

.header-icon {
    padding: 0 10px;
}

.header-icon {
    color: #666;
    height: 4.125rem;
    display: block;
    line-height: 4.125rem;
    text-decoration: none;
    position: relative;
}

.top-nav-item.active {
    background: rgba(255, 255, 255, .2);
}

.top-nav-item-desc, .top-nav-item-img {
    letter-spacing: .05rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--nav-text);
}

.top-nav-item:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, .2);
    color: #000;
}

@media screen and (min-width: 992px) {
    .nav-function-top .page-header {
        order: 1;
    }

    .page-header {
        padding: 0 2rem;
    }
}

.header-icon {
    padding: 0 10px;
}

.seperator {
    border: .5px solid lightgrey;
    margin: 10px;
}

.add-item {
    border-top: .5px solid lightgrey !important;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
}

    .add-item:first-child {
        border-top: unset !important;
    }

    .add-item:hover {
        cursor: pointer;
        background-color: lightgrey;
    }

.dropdown .dropbtn:hover {
    background-color: rgba(255, 255, 255, .2);
}

@media screen and (max-width: 992px) {
    .dropdown {
        display: block !important;
    }

    .dropdown-content {
        display: block !important;
        position: relative !important;
        background-color: unset !important;
        box-shadow: unset !important;
    }

        .dropdown-content li, .dropdown-content div {
            text-align: center !important;
            color: #fff !important;
        }

            .dropdown-content li:hover, .dropdown-content div:hover {
                background-color: rgba(255, 255, 255, .2) !important;
            }

    .search-div {
        order: -1;
        width: 100% !important;
        height: 4.125rem;
    }
}

.nav-item {
    flex-direction: row;
}

.navbar-nav {
    flex: 1;
}

.navbar-light .navbar-toggler {
    color: #fff;
    border-color: #fff;
    background-color: #fff;
}

@media screen and (max-width: 1727px) {
    span .top-nav-item-desc {
        font-size: .75rem;
    }
}


/* Dropdown Button */


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

    .dropdown:hover, .dropdown-content:hover {
        cursor: pointer;
    }

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 225px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

    /* Links inside the dropdown */
    .dropdown-content li, .dropdown-content div {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

        /* Change color of dropdown links on hover */
        .dropdown-content li:hover, .dropdown-content div:hover {
            background-color: #ddd;
        }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: rgba(255, 255, 255, .2);
}

.text-icon {
    color: var(--nav-text) !important;
}

.logoutIcon {
    color: #ff0908 !important;
    font-size: 24px;
    vertical-align: middle;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 10px;
}

    .separator:before {
        margin-right: .25em;
    }

    .separator:after, .separator:before {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #6FB246;
    }

    .separator:after {
        margin-left: .25em;
    }

    .separator:after, .separator:before {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #6FB246;
    }

.bounce-6 {
    animation-name: bounce-6;
    animation-timing-function: ease;
}

@keyframes bounce-6 {
    0% {
        transform: scale(1,1) translateY(0);
    }

    10% {
        transform: scale(1.1,.9) translateY(0);
    }

    30% {
        transform: scale(.9,1.1) translateY(-10px);
    }

    50% {
        transform: scale(1.05,.95) translateY(0);
    }

    57% {
        transform: scale(1,1) translateY(-6px);
    }

    64% {
        transform: scale(1,1) translateY(0);
    }

    100% {
        transform: scale(1,1) translateY(0);
    }
}

.bounce-icon {
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin: 0 auto 0 auto;
    transform-origin: bottom;
}

    .bounce-icon > i {
        color: red !important;
    }

    .bounce-icon > span {
        background: red !important;
        box-shadow: unset !important;
    }

.notifications-btn {
    border-radius: 2px;
    border: .5px solid lightgrey;
}

.focusEvent {
    background-color: rgba(236, 100, 75, .2);
    animation: changeColor 4s;
}

@keyframes changeColor {
    from {
        background-color: inherit;
    }

    to {
        background-color: rgba(236, 100, 75, .2);
    }
}

.show > .dropdown-menu-animated {
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
    -webkit-transform-origin: 29px -50px;
    transform-origin: 29px -50px;
    opacity: 1;
    visibility: visible;
}

/* dropdown-item hover menu*/
.dropdown-menu-animated {
    -webkit-transform: scale(0.8) !important;
    transform: scale(0.8) !important;
    -webkit-transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
    transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
    opacity: 0;
    visibility: hidden;
    display: block;
}

/* various sizes */
.dropdown-menu {
    -webkit-box-shadow: 0 0 15px 1px rgba(90, 80, 105, 0.2);
    box-shadow: 0 0 15px 1px rgba(90, 80, 105, 0.2);
    -webkit-user-select: text;
}

    .dropdown-menu .dropdown-item {
        font-weight: 400;
        cursor: pointer;
    }

    .dropdown-menu.dropdown-sm {
        width: 8rem;
        height: auto;
    }

    .dropdown-menu.dropdown-md {
        width: 14rem;
        height: auto;
    }

    .dropdown-menu.dropdown-lg {
        width: 17.5rem;
        height: auto;
    }

    .dropdown-menu.dropdown-xl {
        width: 500px;
        height: auto;
    }

    .dropdown-menu .dropdown-item:first-child, .dropdown-menu .dropdown-item:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

/* replace bootstrap's default arrow */
/*.dropdown-toggle:after,
.dropleft .dropdown-toggle:before {
  text-align: center;
  display: inline;
  border: 0 !important;
  font-family: 'Font Awesome 5 Pro';
  content: "\f107" !important;
  vertical-align: top !important;
  position: relative; }*/

/*.dropup .dropdown-toggle:after {
  content: "\f106" !important; }

.dropright .dropdown-toggle:after {
  content: "\f105" !important; }

.dropleft .dropdown-toggle:before {
  content: "\f104" !important; }

.nav-item .dropdown-toggle:after {
  font-size: 0.90em; }*/

/* remove arrow */
.dropdown-toggle.no-arrow:before, .dropdown-toggle.no-arrow:after {
    display: none !important;
}

/* dropdown menu multi-level */
.dropdown-menu .dropdown-menu {
    margin: 0;
    padding: 0;
    border-radius: 0;
    position: absolute;
    top: -1px;
    left: 100%;
    background: #fff;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
    transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
    -webkit-transition-delay: 500ms;
    transition-delay: 500ms;
    opacity: 0;
    visibility: hidden;
    display: block;
}

    .dropdown-menu .dropdown-menu .dropdown-item {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

.dropdown-menu .dropdown-multilevel {
    position: relative;
    /* it is displayed on right by default */
    /* add arrow */
}

    .dropdown-menu .dropdown-multilevel.dropdown-multilevel-left > .dropdown-menu {
        right: 100%;
        left: auto;
    }

    .dropdown-menu .dropdown-multilevel > .dropdown-item:first-child:after {
        content: "\f2fb";
        font-family: 'nextgen-icons';
        font-size: inherit;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-right: 1.5rem;
    }

    .dropdown-menu .dropdown-multilevel:hover > .dropdown-item:not(.disabled) {
        background: #f8f9fa;
        color: #1B4B79;
    }

        .dropdown-menu .dropdown-multilevel:hover > .dropdown-item:not(.disabled) + .dropdown-menu {
            -webkit-transition-delay: 0ms;
            transition-delay: 0ms;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: 29px -50px;
            transform-origin: 29px -50px;
            opacity: 1;
            visibility: visible;
        }

.dropdown-menu {
    right: 0 !important;
    position: absolute;
    will-change: transform;
    width: 35vw;
    max-height: 500px !important;
    padding: 0;
}

#app-notification .dropdown-menu {
    left: unset !important;
}

.react-bootstrap-table-pagination .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0 !important;
    z-index: 2000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0;
    margin: .125rem 0 0;
    font-size: .8125rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0,0,0,.15);
    border-radius: 4px;
    width: 75px !important;
}

    .react-bootstrap-table-pagination .dropdown-menu.show {
        display: block !important;
    }

.dropdown-menu.show {
    transition: .5s;
    -webkit-animation: animateFadeInUp .5s;
    -webkit-animation-name: bounce;
    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: 10;
    -webkit-animation-direction: alternate;
}

.dropdown-menu.search {
    right: 0 !important;
    left: unset !important;
    position: absolute;
    will-change: transform;
    min-width: 575px;
    width: 35vw;
    max-height: 500px !important;
    margin: 5px;
}

    .dropdown-menu.search.show {
        transition: .5s;
        -webkit-animation: animateFadeInUp .5s;
        -webkit-animation-name: bounce;
        -webkit-animation-duration: 4s;
        -webkit-animation-iteration-count: 10;
        -webkit-animation-direction: alternate;
    }

.rounded-top {
    border-radius: 4px 4px 0 0;
}

.mb-2, .my-2 {
    margin-bottom: .5rem !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-center {
    justify-content: center !important;
}

.d-flex {
    display: flex !important;
}

.rounded-right, .rounded-top {
    border-top-right-radius: 4px !important;
}

.rounded-top {
    border-top-left-radius: 4px !important;
}

.dropdown-header {
    /*display: block;
    padding: .3125rem 1.5rem;
    margin-bottom: 0;
    font-size: .75rem;
    color: #868e96;
    background: #6FB246;
    white-space: nowrap;*/
    background-color: #ededed;
    margin: 0;
    padding: 10px !important;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px !important;
    border-bottom: 2px solid #6FB246 !important;
}

.nav-tabs-clean {
    height: 45px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.nav-tabs {
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-tabs .nav-item .nav-link.active:not(:hover) {
    background-color: lightgrey;
    color: #333;
}

.nav .nav-link:not([class^=btn-]):not([class*=" btn-"]) {
    font-weight: 500;
    font-size: .8125rem;
}

.nav-tabs .nav-item .nav-link:not(:hover) {
    color: inherit;
}

.nav-tabs-clean .nav-item .nav-link.active {
    border-bottom: 1px solid #6FB246;
    color: #1b4b79;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #1b4b79;
}

.nav-tabs-clean .nav-item:hover {
    cursor: pointer;
}

.nav-tabs-clean .nav-item .nav-link {
    border-radius: 0;
    border: 0;
    height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
}

.tab-content {
    border-top: none;
    background: #fff;
    overflow-y: auto;
    min-height: 85vh;
    padding: 0 1rem;
}

.tab-notification {
    height: 55vh !important;
    min-height: 55vh !important;
}

.dropdown-menu-footer {
    background: #f7f9fa;
    height: 100%;
    display: flow-root;
    padding: 10px;
}

.fs-21 {
    font-size: 21px;
}

.openEventRow {
    padding: 0 5px 15px;
    border-bottom: 1px solid #6FB246;
}

    .openEventRow:hover {
        cursor: pointer;
    }

.notifications-btn {
    border-radius: 2px;
    border: .5px solid #d3d3d3;
    font-size: 12px;
}

@media (min-width: 992px) {
    .nav-function-top .page-header .badge.badge-icon {
        box-shadow: 0 0 0 1px #7a59ad;
    }
}

.badge.badge-icon {
    position: absolute;
    display: inline-block;
    background-color: #666;
    color: #fff;
    box-shadow: 0 0 0 1px #fff;
    cursor: default;
    border: 1px solid transparent;
    font-size: .625rem;
    min-width: 1rem;
    max-width: 1.6875rem;
    padding: 0 3px;
    border-radius: 1.25rem;
    font-weight: 500;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
}

.page-header .badge-icon {
    left: 1.5625rem;
    top: 1.09375rem;
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 85%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 4px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
